import { createMuiTheme } from '@material-ui/core/styles';

// According to recommendations in Refactoring UI.
const spaceToPixels = n => {
  switch (n) {
    case 1:
      return 4;
    case 2:
      return 8;
    case 3:
      return 12;
    case 4:
      return 16;
    case 5:
      return 24;
    case 6:
      return 32;
    case 7:
      return 48;
    case 8:
      return 64;
    case 9:
      return 96;
    case 10:
      return 128;
    case 11:
      return 192;
    case 12:
      return 256;
    case 13:
      return 384;
    case 14:
      return 512;
    case 15:
      return 640;
    case 16:
      return 768;
    default:
      return 0;
  }
};

const theme = createMuiTheme({
  breakpoints: {
    // According to suggestions in https://ricostacruz.com/til/css-media-query-breakpoints.
    values: {
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
    },
  },
  palette: {
    primary: { main: 'hsl(167, 23%, 55%)' },
    secondary: { main: 'hsl(13, 90%, 69%)' },
  },
  typography: {
    fontFamily: ['Montserrat, helvetica, sans-serif'].join(','),
    fontSize: 12,
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: ['Montserrat, helvetica, sans-serif'].join(','),
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: ['Montserrat, helvetica, sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: ['Montserrat, helvetica, sans-serif'].join(','),
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: ['Montserrat, helvetica, sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
  },
  spacing: (...args) => {
    switch (args.length) {
      case 1:
        return spaceToPixels(args[0]);
      case 2:
        return `${spaceToPixels(args[0])} ${spaceToPixels(args[1])}`;
      case 4:
        return `${spaceToPixels(args[0])} ${spaceToPixels(args[1])} ${spaceToPixels(
          args[2],
        )} ${spaceToPixels(args[3])}`;
      default:
        return 0;
    }
  },
});

const headingFont = ['Montserrat, helvetica, sans-serif'].join(',');

theme.typography.h1 = {
  fontFamily: headingFont,
  fontWeight: 800,
  fontSize: 70,
  lineHeight: 1.167,
  letterSpacing: '-0.01562em',
  [theme.breakpoints.up('md')]: {
    fontWeight: 800,
    fontSize: 96,
    lineHeight: 1.167,
    letterSpacing: '-0.01562em',
  },
}

theme.typography.h2 = {
  fontFamily: headingFont,
  fontWeight: 900,
  fontSize: 40,
  lineHeight: 1.2,
  letterSpacing: '-0.00833em',
  [theme.breakpoints.up('md')]: {
    fontWeight: 900,
    fontSize: 70,
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },
}

theme.typography.h3 = {
  fontFamily: headingFont,
  fontWeight: 900,
  fontSize: 32,
  lineHeight: 1.167,
  letterSpacing: '0em',
  [theme.breakpoints.up('md')]: {
    fontWeight: 900,
    fontSize: 44,
    lineHeight: 1.167,
    letterSpacing: '0em',
  },
}

theme.typography.h4 = {
  fontFamily: headingFont,
  fontWeight: 700,
  fontSize: 28,
  lineHeight: 1.235,
  letterSpacing: '0.00735em',
  [theme.breakpoints.up('md')]: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.235,
    letterSpacing: '0.00735em',
  },
}

theme.typography.h5 = {
  fontFamily: headingFont,
  fontWeight: 400,
  fontSize: '1.5rem',
  lineHeight: 1.334,
  letterSpacing: '0em',
  [theme.breakpoints.up('md')]: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
}

theme.typography.h6 = {
  fontFamily: headingFont,
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: 1.6,
  letterSpacing: '0.0075em',
  [theme.breakpoints.up('md')]: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
}



export default theme;
